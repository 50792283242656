<template>
  <!-- <div class="text-center" style="background-color:red"> -->
    <!-- <div> -->
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    style="paddingTop:40px; margin:auto"
    autocomplete="off"
    @keyup.native.enter="validate"
  >
    <div class="text-center">
      <h2>Iniciar sesión</h2>
      <h4>Utilice una cuenta para iniciar sesión</h4>
    </div>

    <br>
      <!-- <hr style="marginLeft:-2000px; marginRight:-2000px"> -->
      <hr style="margin-left:10px; margin-right:10px">
    <br>

    <div class="text-center">
      <v-text-field
        outlined=""
        v-model="usuario"
        :rules="usuarioRules"
        label="Usuario"
        required
        style="width:300px; marginTop:20px; margin-left:auto; margin-right:auto"
        prepend-icon="mdi-account"
        type="text"
        class="mt-0"
      ></v-text-field>

      <div v-if="usuario != ''" style="width:300px" class="mt-n7 mx-auto text-end">
        <v-btn
        color="primary" small text
        class="px-0"
        :disabled="loading"
        @click="recordarPass"
        tabindex="1"
        >
        Recordar contraseña
        </v-btn>
      </div>

      <v-text-field
        outlined=""
        v-model="password"
        :rules="passwordRules"
        label="Contraseña"
        required
        style="width:300px; margin-left:auto; margin-right:auto"
        prepend-icon="mdi-lock"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
      
      <v-btn
      color="primary"
      :loading="loading"
      @click="validate"
      style="width:150px"
      >
      Iniciar sesión
      </v-btn>
    </div>

  </v-form>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz', 'per', 'emp'])
    },
    data: () => ({
      loading: false,
      showPassword: false,
      valid: true,
      usuario: '',
      usuarioRules: [
        v => !!v || 'El usuario es obligatorio',
      ],
      password: '',
      passwordRules: [
        v => !!v || 'La contraseña es obligatoria',
      ],
      empresa: {},
      tituloPrincipal: '',
      tituloSecundario: ''
    }),

    methods: {
      validate () {
        this.$refs.form.validate();
        if (this.valid)
        {
            this.enviar();
        }
      },
      enviar () {
        if(this.loading) { return; }
        this.loading = true;
        var loginObj = { UserName: this.usuario, Password: this.password, browserData: navigator.userAgent };
        var loginJson = JSON.stringify(loginObj);

         axios({ method: "POST", "url": this.urlRaiz + "/api/login/authenticate", "data": loginJson, "headers": { "content-type": "application/json" } })
            .then(result => {
                var authToken = result.data;
                localStorage.setItem('token', authToken);
                this.$store.dispatch('AsignarUsuario').then( () => {
                    this.$store.dispatch('GetMensajes');
                })
                .catch(() => {
                    this.loading = false;
                });
            })
            .catch(error => {
                if (error.response.status == 401){
                    alert('Usuario o contraseña incorrecto');
                }
                else {
                    alert(error);
                }
                this.password = '';
                this.loading = false;
            })
      },
      recordarPass () {
        if(this.loading) { return; }
        this.loading = true;

        const dataObj = { user: this.usuario, desarrollo: this.$store.state.desarrollo };
        const dataJson = JSON.stringify(dataObj);

         axios({ method: "POST", "url": this.urlRaiz + "/api/login/recoverpassword", "data": dataJson, "headers": { "content-type": "application/json" } })
            .then(() => {
                this.loading = false;
                alert('Contraseña recuperada. Compruebe su correo electrónico.');
            })
            .catch(error => {
              if (error != null){
                  this.loading = false;
                  alert('No se ha podido recuperar la contraseña.');
              }
            })
      }
    },
    mounted(){
    }
  }
</script>